export default function tempData() {
  const data = [
    {
      id: '',
      error_id: '',
      title: '',
      active: '',
      created_at: '',
      updated_at: '',
    },
  ]
  return data
}

// export default tempData

// export function fetchAlertTypes(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/dataverse/alerttypes', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushAlertTypes(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchMediaTypes(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushMediaTypes(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchNotificationTypes(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushNotificationTypes(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchProfileLinks(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushProfileLinks(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchPurchaseTypes(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushPurchaseTypes(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchUserRoleTypes(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushUserRoleTypes(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchAlertRecipients(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushAlertRecipients(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function fetchDefaultTags(ctx, queryParams) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get('/api/dataverse', { params: queryParams })
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }

// export function pushDefaultTags(ctx, { id }) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/dataverse/${id}`)
//       .then(response => resolve(response))
//       .catch(error => reject(error))
//   })
// }
