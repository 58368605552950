import endpoints from './endpoints'

export default class ApiService {
  // Will be used by this service for making API calls
  axiosIns = null

  // apiConfig <= Will be used by this service
  apiConfig = { ...endpoints }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, serviceArgs) {
    this.axiosIns = axiosIns
    this.apiConfig = { ...this.apiConfig, ...serviceArgs }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(config => config, error => Promise.reject(error))

    this.axiosIns.interceptors.response.use(
      response => {
        if (response.status < 200 || response.status >= 300) {
          if (response.data.error !== undefined) {
            throw new Error(response.data.error)
          } else if (response.data.Failed !== undefined) {
            throw new Error(response.data.Failed)
          } else if (response.data.message !== undefined) {
            throw new Error(response.data.message)
          } else {
            throw new Error('An unknown error occurred. Please try again.')
          }
        }
        return response.data.success
      },
      error => Promise.reject(error),
    )
  }

  getDashboard(url, ...args) {
    // return args
    const request = this.axiosIns.get(this.apiConfig.dashboardEndpoint + url, ...args)
    return request
  }

  getDataverse(url, ...args) {
    // return args
    const request = this.axiosIns.get(this.apiConfig.dataverseEndpoint + url, ...args)
    return request
  }

  getUser(url, ...args) {
    // return args
    const request = this.axiosIns.get(this.apiConfig.userEndpoint + url, ...args)
    return request
  }

  getCreator(url, ...args) {
    // return args
    const request = this.axiosIns.get(this.apiConfig.creatorEndpoint + url, ...args)
    return request
  }

  register(...args) {
    return this.axiosIns.post(this.apiConfig.registerEndpoint, ...args)
  }
}
