// import ApiEndpoints from './endpoints'
import ApiService from './service'
// import DataverseClient from './clients/dataverse'

export default function mlfApi(axiosIns, serviceArgs) {
  const api = new ApiService(axiosIns, serviceArgs)

  return {
    api,
  }
}

// export default function dataverseApi(axiosIns) {
//   const dataverseApi = new DataverseClient(axiosIns)

//   return {
//     dataverseApi,
//   }
// }
